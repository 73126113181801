<template>
	<div class="mt-16 md:mt-0">
		<div>
			<div
				class="flex items-start justify-between mb-4 bg-verdedark w-full fixed md:static"
			>
				<div class="text-white font-bold mt-2 ml-2">
					<span
						>Vendedor:
						{{
							firstLetterUpperCase($route.params.name.replaceAll("_", " "))
						}}</span
					>
				</div>
				<div class="mt-1">
					<span class="text-white mt-2">Filtar por: </span>
					<select
						id="select"
						class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
						v-model="filtroSelect"
					>
						<option value="todos">Todos</option>
						<option value="asignado">Asignadas</option>
						<option value="reasignado">Reasignadas</option>
						<option value="espera">Espera</option>
						<option value="completado">Completadas</option>
						<option value="finalizado">Finalizadas</option>
						<option value="entregado">Entregadas</option>
						<option value="anulado">Anuladas</option>
						<option value="pagado">Pagadas</option>
						<option value="noPagado">No pagadas</option>
					</select>
				</div>
				<div class="flex justify-center">
					<router-link
						:to="{
							name: 'EditarVendedor',
							params: {
								var: $route.params.id,
								nombre: $route.params.name.replace(/\s/g, '_')
							}
						}"
						><button
							class="text-white font-bold py-2 px-4 inline-flex items-center focus:outline-none"
						>
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6 mr-2 fill-current text-white"
							>
								<path
									d="M4.17473 2.06016C5.68679 0.550767 8.13538 0.550767 9.64744 2.06016L11.0174 3.4376L25.6002 18.0127L25.6312 18.0439C25.6387 18.0514 25.6387 18.0594 25.6467 18.0594C25.6622 18.0826 25.6854 18.1056 25.7007 18.1289C25.7007 18.1366 25.7087 18.1366 25.7087 18.1444C25.7242 18.1676 25.7317 18.1831 25.7474 18.2064C25.7549 18.2141 25.7549 18.2216 25.7627 18.2296C25.7704 18.2529 25.7782 18.2684 25.7861 18.2916C25.7861 18.2991 25.7936 18.2991 25.7936 18.3071L29.0291 28.0366C29.124 28.3136 29.0519 28.6203 28.8434 28.8259C28.6969 28.9704 28.4994 29.0513 28.2938 29.0506C28.2098 29.0491 28.1265 29.036 28.0461 29.0118L18.3242 25.7686C18.3166 25.7686 18.3166 25.7686 18.3087 25.7611C18.2842 25.7538 18.2607 25.7434 18.2392 25.7299C18.2331 25.7291 18.2278 25.7265 18.2234 25.7224C18.2004 25.7069 18.1694 25.6911 18.1462 25.6756C18.1232 25.6604 18.0997 25.6371 18.0765 25.6216C18.0687 25.6136 18.0612 25.6136 18.0612 25.6061C18.0532 25.5984 18.0377 25.5909 18.03 25.5751L2.07722 9.62239C0.567829 8.11033 0.567829 5.66174 2.07722 4.14992L4.17473 2.06016ZM18.5718 23.9495L23.9591 18.5625L10.4755 5.07895L5.08826 10.4659L18.5718 23.9495ZM24.7179 19.9944L20.0115 24.7006L27.0786 27.0535L24.7179 19.9944ZM3.16847 8.53865L3.98903 9.36695L9.3765 3.97947L8.54796 3.15141C7.64169 2.24587 6.17273 2.24587 5.26622 3.15141L3.16096 5.25667C2.26148 6.16705 2.26487 7.63262 3.16847 8.53865Z"
									fill="white"
								/>
							</svg>
							<span class="text-white underline">Editar vendedor</span>
						</button></router-link
					>
				</div>
			</div>

			<div class="flex justify-between mb-10">
				<div
					class="w-80 ml-5 border-gray-300 border h-32 rounded-lg grid items-center"
				>
					<div class="flex ml-12">
						<div>
							<span class="font-bold mr-2">Fecha Inicio:</span>
							<input
								class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
								type="date"
								v-model="fechaInicio"
							/>
						</div>
						<div class="w-36">
							<span class="font-bold mx-2">Fecha Fin:</span>
							<input
								class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
								type="date"
								v-model="fechaFin"
							/>
						</div>
					</div>
					<div class="flex justify-around">
						<Botonp
							class="underline"
							botonpText="Reset"
							@click="resetData()"
						></Botonp>
						<Botonp
							class="underline"
							botonpText="Buscar"
							@click="nuevaBusqueda()"
						></Botonp>
					</div>
				</div>
				<div class="flex flex-wrap content-center justify-end mr-10">
					<div class="">
						<div class="flex content-center flex-wrap">
							<div class="font-bold">Comisiones Total Bs:</div>
						</div>
						<Campo
							:campoCoti="formato(dataVendedorQuery.totalComision)"
							class="mr-10"
						/>
					</div>
				</div>
			</div>
			<div class="flex justify-center mb-5">
				<span v-if="showMessage">{{ message }}</span>
			</div>
			<div class="flex items-center justify-center ml-56 md:ml-0">
				<table class="table-auto">
					<thead>
						<tr>
							<td class="border-2 px-4 py-2 font-bold text-center">
								# Orden de compra
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Nombre Cliente
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Fecha Entrega
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">Estatus</td>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Comisión Bs
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">Estado</td>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(OrdenCompra, k) in dataVendedorQuery.listaOrdenesCompras"
							:key="k"
						>
							<td class="border-2 text-center px-4 py-4">
								<router-link
									class="hover:text-blue-500 hover:underline"
									:to="{
										name: 'PedidoID',
										params: {
											id: OrdenCompra.id.padStart(4, '0'),
											nombre: OrdenCompra.name,
											telefono: OrdenCompra.numero_telefono_movil
										}
									}"
								>
									<div>{{ OrdenCompra.id.padStart(4, "0") }}</div></router-link
								>
							</td>
							<td class="border-2 text-center px-4 py-4">
								<div>
									{{ firstLetterUpperCase(OrdenCompra.name) }}
								</div>
							</td>
							<td class="border-2 text-center px-4 py-4">
								<div>
									{{ formatoFecha(OrdenCompra.fecha_entrega) }}
								</div>
							</td>
							<td class="border-2 text-center px-4 py-4">
								<div>{{ firstLetterUpperCase(OrdenCompra.estatus) }}</div>
							</td>
							<td class="border-2 text-center px-4 py-4">
								<div>{{ formato(OrdenCompra.comision_vendedor) }}</div>
							</td>
							<td class="border-2 text-center px-4 py-4">
								<vSelect
									class="w-40"
									:options="['No Pagado', 'Pagado']"
									:key="selectRefresh[k]"
									:placeholder="verificarEstado(OrdenCompra.pago_vendedor)"
									@input="estado => cambiarEstado(OrdenCompra, estado)"
								></vSelect>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="dataVendedorQuery.continuaData"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
		<div class="flex justify-center mb-5">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex justify-center mb-10">
			<div>
				<Botonp
					class="underline mr-10"
					botonpText="Guardar Cambios"
					@click="guardarCambios"
				></Botonp>
			</div>
		</div>
	</div>
</template>
<script>
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";
import Botonp from "@/components/botonPrimario";
import Campo from "@/components/camposCotizacion";
import "@/assets/styles/vue-select.css";
import vSelect from "vue-select";

export default {
	components: {
		Botonp,
		Campo,
		vSelect
	},
	data() {
		return {
			filtroSelect: "noPagado",
			dataInicio: 0,
			dataFin: 10,
			filtroEstatus: null,
			filtroPagado: null,
			fechaInicio: null,
			fechaFin: null,
			dataVendedorQuery: {},
			selectRefresh: [],
			showMessage: false,
			message: ""
		};
	},
	async mounted() {
		this.$store.state.isLoading = true;
		this.modificarFiltro();
		await this.vendedoresDataQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		cambiarEstado(compra, estado) {
			compra.pago_vendedor = estado;
		},
		verificarEstado(estado) {
			if (estado === "1") {
				return "Pagado";
			} else return "No Pagado";
		},
		nuevaBusqueda() {
			//Validacion de data
			let fechaInicio = new Date(this.fechaInicio);
			let fechaFin = new Date(this.fechaFin);
			let ejecutarQuery = false;

			if (fechaInicio > fechaFin) {
				alert("La fecha de inicio tiene que ser antes de la fecha de fin");
			} else {
				if (this.fechaInicio === null && this.fechaFin === null) {
					this.dataInicio = 0;
					this.dataFin = 10;
					this.fechaInicio = null;
					this.fechaFin = null;
					ejecutarQuery = true;
				} else {
					if (this.fechaInicio === null || this.fechaFin === null) {
						alert("Por favor elegir fecha de inicio y fin.");
					} else {
						ejecutarQuery = true;
					}
				}
			}
			if (ejecutarQuery) {
				this.modificarFiltro();
				this.vendedoresDataQuery();
			}
		},
		resetData() {
			this.dataInicio = 0;
			this.dataFin = 10;
			this.fechaInicio = null;
			this.fechaFin = null;
		},
		async vendedoresDataQuery() {
			this.showMessage = true;
			this.message = "Cargando, espere por favor";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/vendedorPedidoId.gql"),
					variables: {
						vendedor_id: parseInt(this.$route.params.id),
						dataInicio: this.dataInicio,
						dataFin: this.dataFin,
						filtroEstatus: this.filtroEstatus,
						filtroPagado: this.filtroPagado,
						fechaInicio: this.fechaInicio,
						fechaFin: this.fechaFin
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.dataVendedorQuery = data.data.vendedorPedidoId;
					//Variable de control para mostrar o no detalles
					for (let i = 0; i < 10; i++) {
						this.selectRefresh[i] = !this.selectRefresh[i];
					}
					this.showMessage = true;
					this.message = "Completado";
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					this.showMessage = true;
					this.message = "Error en carga, por favor vuelva a intentarlo";
				});
		},
		cargarDataAnterior() {
			this.dataInicio -= 10;
			this.dataFin -= 10;
			this.vendedoresDataQuery();
		},
		cargarDataSiguiente() {
			this.dataInicio += 10;
			this.dataFin += 10;
			this.vendedoresDataQuery();
		},
		guardarCambios() {
			let estadoActual = "";
			let actualizarPagos = [];

			for (
				let i = 0;
				i < this.dataVendedorQuery.listaOrdenesCompras.length;
				i++
			) {
				if (
					this.dataVendedorQuery.listaOrdenesCompras[i].pago_vendedor ===
						"No Pagado" ||
					this.dataVendedorQuery.listaOrdenesCompras[i].pago_vendedor === "0" ||
					this.dataVendedorQuery.listaOrdenesCompras[i].pago_vendedor === 0
				) {
					estadoActual = "0"; // No Pagado
				} else {
					estadoActual = "1"; // Pagado
				}
				actualizarPagos.push({
					compra_id: parseInt(this.dataVendedorQuery.listaOrdenesCompras[i].id),
					pago_vendedor: estadoActual
				});
			}
			this.showMessage = true;
			this.message = "Guardando Cambios, por favor espere";
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					// Query
					mutation: require("@/graphql/mutations/PagoVendedorMutation.gql"),
					// Parameters
					variables: {
						data: actualizarPagos
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.vendedoresDataQuery();
					this.showMessage = true;
					this.message = "Cambios guardados con exito";
					this.$store.state.isLoading = false;
					alert("Cambios guardados con exito");
				})
				.catch(error => {
					this.showMessage = true;
					this.$store.state.isLoading = false;
					this.message =
						"Se ha producido un error por favor vuelva a intentarlo";
					alert("Se ha producido un error por favor vuelva a intentarlo");
				});
		},
		modificarFiltro() {
			this.filtroEstatus = null;
			this.filtroPagado = null;
			if (this.filtroSelect != "todos") {
				if (
					this.filtroSelect === "pagado" ||
					this.filtroSelect === "noPagado"
				) {
					this.filtroEstatus = null;
					if (this.filtroSelect === "pagado") {
						this.filtroPagado = "1";
					} else {
						this.filtroPagado = "0";
					}
				} else {
					this.filtroEstatus = this.filtroSelect;
					this.filtroPagado = null;
				}
			}
		}
	}
};
</script>
